import { ActionTypes, ApartmentSearchActions } from "."
import { resetState } from "../UserContext"

const apartmentSearchReducer = (state: ApartmentSearchState, action: ApartmentSearchActions): ApartmentSearchState => {
  // console.debug("apartmentSearchReducer", state, action)
  switch (action.type) {
    case ActionTypes.SetApartmentSearchParams: {
      return { ...state, params: { ...action.payload }, changeCount: state.changeCount + 1 }
    }
    case ActionTypes.SetApartmentSearchResultMode: {
      return { ...state, resultMode: action.payload }
    }
    case ActionTypes.ResetApartmentSearch: {
      return { ...resetState.apartmentSearch, changeCount: state.changeCount + 1 }
    }
    case ActionTypes.ResetApartmentSearchParams: {
      // TODO nyt sama kuin ActionTypes.SetApartmentSearchParams mutta voisi pakottaa osan arvoista oletuksiksi eli sallia vain tiettyjen parametrien välitys
      return { ...state, params: { ...action.payload }, changeCount: state.changeCount + 1 }
    }
    default:
      throw new Error("Bad Action Type")
  }
}

export default apartmentSearchReducer
