import React from "react"
import { useTranslation } from "react-i18next"

type PageContextProps = {
  locale: "fi" | "en"
}

export const PageContext = React.createContext<PageContextProps>({
  locale: "fi",
})

type PageContextProviderProps = {
  value: PageContextProps
  children: React.ReactNode
}

export const PageContextProvider = (props: PageContextProviderProps) => {
  // console.debug("PageContentProvider init", props)
  const { value, children } = props

  const { i18n } = useTranslation()
  i18n.changeLanguage(value.locale)
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
