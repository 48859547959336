import i18next from "i18next"
// import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const resources = {
  fi: require("./translations/fi.json"),
  en: require("./translations/en.json"),
}

/**
 * i18next configuration
 * https://www.i18next.com/overview/configuration-options
 */

i18next
  // .use(initReactI18next) // bind react-i18next to the instance (tehdään wrapRootElement() avulla)
  .use(LanguageDetector)
  .init({
    fallbackLng: "fi",
    resources,
    ns: ["common", "footer"],
    defaultNS: "common",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    saveMissing: true,
    // missingKeyHandler: (lng, ns, key, fallbackValue) => {
    //   console.debug("Key missing", lng, ns, key, fallbackValue)
    // },
    interpolation: {
      escapeValue: false, // not needed for react, react is already safe from xss
    },
    react: {
      useSuspense: false, // kokeilu. Halutaan SSR ja Suspense ei oikein sovi sinne (vielä?)
      bindI18n: false, // estää re-rendauksen languageChange eventistä (oletus). Localen vaihto tehdään itse PageWrapper komponentissa (lisätty wrapPageElement() avulla).
    },
    // resource loading in init function, defaults to async (using setTimeout).
    // Set it to false if your backend loads resources sync - that way calling i18next.t after init is possible without relying on the init callback.
    // This option only works for sync (blocking) loading backend, like i18next-sync-fs-backend!
    initImmediate: false, // Important for SSR to work
    preload: ["fi", "en"], // array of languages to preload. Important on serverside to assert translations are loaded before rendering views.
  })

i18next.languages = ["fi", "en"]

export { i18next, resources }
