exports.components = {
  "component---src-gatsby-templates-dato-route-tsx": () => import("./../../../src/gatsby-templates/datoRoute.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-route-tsx" */),
  "component---src-gatsby-templates-hood-page-template-tsx": () => import("./../../../src/gatsby-templates/HoodPageTemplate.tsx" /* webpackChunkName: "component---src-gatsby-templates-hood-page-template-tsx" */),
  "component---src-gatsby-templates-tampuuri-property-tsx": () => import("./../../../src/gatsby-templates/tampuuriProperty.tsx" /* webpackChunkName: "component---src-gatsby-templates-tampuuri-property-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-localized-files-en-404-tsx": () => import("./../../../src/pages/localized-files/en/404.tsx" /* webpackChunkName: "component---src-pages-localized-files-en-404-tsx" */),
  "component---src-pages-localized-files-fi-404-tsx": () => import("./../../../src/pages/localized-files/fi/404.tsx" /* webpackChunkName: "component---src-pages-localized-files-fi-404-tsx" */)
}

