import React, { createContext, Dispatch, useEffect, useReducer } from "react"
import { ApartmentSearchActions, SearchResultModes } from "./userReducers"
import apartmentSearchReducer from "./userReducers/apartmentSearch"

/**
 * Globaali, navigaation yli säilyvä säilö käyttäjän asetuksille ja muulle tilalle.
 * Käytetään alkuun asuntohaun parametrien ja tuloslistan tyylin (kartta vai lista) tallentamiseen.
 */

const LOCAL_STORAGE_KEY = "userContext"

// tyypitys tilaobjektille
export type UserState = {
  apartmentSearch: ApartmentSearchState // asuntohausta tallennettavat asiat
  // lisätään tähän ne muut mahdolliset tallennetavat asiat
}

// tyypitys dispatch() parametreille.
// Jatkossa tätä voi laajentaa helposti: type Action = ApartmentSearchActions | Theme
type Action = ApartmentSearchActions

function readLocalStorage<T>(key: string): T | null {
  // console.debug("Reading userState from localStorage, the readLocalStorage() function", key)
  if (typeof window === `undefined` || typeof localStorage === undefined) {
    return null
  }
  const rawValue = localStorage.getItem(key)
  if (!rawValue) {
    return null
  }
  return JSON.parse(rawValue)
}

function writeLocalStorage<T>(key: string, data: T): void {
  // console.debug("Writing userState to localStorage, the writeLocalStorage() function", key, data)
  try {
    localStorage.setItem(
      key,
      JSON.stringify(data, (_, value) => {
        return value === "" ? undefined : value
      })
    )
  } catch (err) {
    console.error(err)
  }
}

const localStorageState = readLocalStorage(LOCAL_STORAGE_KEY)

export const resetState = {
  apartmentSearch: {
    // asuntohausta tallennettavat asiat
    resultMode: SearchResultModes.List, // lista vai kartta?
    params: Object.create(null), // asuntohakulomakkeen oletusarvot
    changeCount: 0,
  },
}

export const initialState = localStorageState ? (localStorageState as UserState) : resetState

/**
 * Käyttäjän asetusten/tilan datan paljastava konteksti
 */
export const UserStateContext = createContext<UserState | undefined>(undefined)

/**
 * Käyttäjän asetusten/tilan reducerin paljastava konteksti
 */
export const UserDispatchContext = createContext<Dispatch<Action> | undefined>(undefined)

/**
 * Päätason reducer funktio. Tehdään näin jotta uusien reducereiden lisääminen olisi helppompaa
 * ja niiden logiikka voidaan pitää erillään.
 */
const mainReducer = ({ apartmentSearch }: UserState, action: Action): UserState => ({
  apartmentSearch: apartmentSearchReducer(apartmentSearch, action as ApartmentSearchActions),
  // theme: themeReducer( ... esimerkki ...)
})

type UserContextProviderProps = {
  children: React.ReactNode
}

const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const localStorageData = readLocalStorage<UserState>(LOCAL_STORAGE_KEY)
  const [state, dispatch] = useReducer(mainReducer, localStorageData !== null ? localStorageData : initialState)

  // console.debug("UserContextProvider init", localStorageData)

  useEffect(() => {
    // console.debug("UserContextProvider: Writing userState to localStorage on useEffect hook", state)
    writeLocalStorage(LOCAL_STORAGE_KEY, state)
  }, [state])

  // Wrapataan State ja Dispatch erikseen jotta komponenteissa voi importata vain tarvitsemansa
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

export default UserContextProvider
