export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

export enum SearchResultModes {
  List = "list",
  Map = "map",
}

export enum ActionTypes {
  /** Ylikirjoittaa Asuntohaun hakuparametrit */
  SetApartmentSearchParams = "SEARCH_PARAMS_CHANGE",
  /** Ylikirjoittaa tulosten esitystavan */
  SetApartmentSearchResultMode = "SEARCH_RESULMODE_CHANGE",
  /** Nollaa Asuntohaku kokonaan; laskuri, parameterit ja esitysmuoto oletusarvoihin */
  ResetApartmentSearch = "SEARCH_RESET",
  /** Nollaa Asuntohaun parametrit */
  ResetApartmentSearchParams = "SEARCH_PARAMS_RESET",
}

// payloadin muoto eri action typeillä
type ApartmentSearchPayload = {
  [ActionTypes.SetApartmentSearchParams]: ApartmentSearchParameters
  [ActionTypes.SetApartmentSearchResultMode]: SearchResultMode
  [ActionTypes.ResetApartmentSearch]: never
  [ActionTypes.ResetApartmentSearchParams]: ApartmentSearchParameters
}

export type ApartmentSearchActions = ActionMap<ApartmentSearchPayload>[keyof ActionMap<ApartmentSearchPayload>]
